module.exports = {
    drawerWidth: 280,
    featuresInDev: true,
    
    shopThinkmelius: 'http://umustlook.com/',

    socketUrl: 'https://api.stg.tradersgemini.com',
    proxyUrl: 'https://api.stg.tradersgemini.com/',
};

 